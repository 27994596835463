import React, { Component } from "react"
import Button from '@material-ui/core/Button'
import SEO from "../components/seo"
import { navigate } from "@reach/router"

import SiteContext from "../context/SiteContext"
import Layout from "../components/layout"
import emacoHomeImage from '../images/intro_image_masteremaco.jpg'
import flowHomeImage from '../images/home_image_masterflow.jpg'
import sealHomeImage from '../images/home_image_masterseal.jpg'

class IndexPage extends Component {

    static contextType = SiteContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        console.log('.')
    }

    handleCheckbox = value => event => {
        if (event.target.checked) {
            this.setState({ checked: true });
        } else {
            this.setState({ checked: false });
        }
    }

    handleEmacoClick = () => {
        this.context.changeSite('masteremaco')
        navigate('/masteremaco')
    }

    handleFlowClick = () => {
        this.context.changeSite('masterflow')
        navigate('/masterflow')
    }

    handleSealClick = () => {
        this.context.changeSite('masterseal')
        navigate('/masterseal')
    }

    render() {

        return (
            <SiteContext.Consumer>
                {siteContext => (
                    <Layout>
                        <SEO title="Master Builders Solutions Product Selectors" />
                        <div className="wrapper wrapper--home-page">
                                <h1>Master Builders Solutions Product Selectors</h1>
                                <p className="large-text">Selecting the right product is critical to the success of any project. We realize navigating through information on-line can be challenging and time consuming. Finding the best product from our extensive portfolio is easier than ever before. Master Builders Solutions now provides a simple to use digital platform to help guide you through this process. Whether you are working on a building, parking structure, stadium, high performing floor or industrial grouting application, these selector tools will navigate you to the appropriate products.</p>
                                <span className="spacer"></span>
                                <div className="intro-cards">
                                    <div className="intro-card">
                                        <img src={emacoHomeImage} alt="" />
                                        <h2>MasterEmaco Concrete Repairs Selector Tool</h2>
                                        <p>The MasterEmaco Concrete Repair Selector Tool helps estimate and choose the correct concrete repair product on construction projects.</p>
                                        <Button className="button col-12" onClick={this.handleEmacoClick}>Continue to MasterEmaco</Button>
                                    </div>
                                    <div className="intro-card">
                                        <img src={flowHomeImage} alt="" />
                                        <h2>MasterFlow Grout Selector Tool</h2>
                                        <p>The MasterFlow Grout Selector Tool helps you find the best precision grouting solutions to meet your project needs in just a few clicks.</p>
                                        <Button className="button col-12" onClick={this.handleFlowClick}>Continue to MasterFlow</Button>
                                    </div>
                                    <div className="intro-card">
                                        <img src={sealHomeImage} alt="" />
                                        <h2>MasterSeal Traffic Coating Selector Tool</h2>
                                        <p>The MasterSeal Traffic Coating Selector Tool helps you quickly select the right traffic coating to meet your project needs.</p>
                                        <Button className="button col-12" onClick={this.handleSealClick}>Continue to MasterSeal</Button>
                                    </div>
                                </div> 
                        </div>
                    </Layout>
                )}
            </SiteContext.Consumer>
        )
    }
}

export default IndexPage